<template>
  <div>
    <div class="pad-tb10 sctp-bg-white mg-b5">
      <div class="slider-card-title">申请退款</div>
    </div>
    <template v-if="orderInfo">
      <template v-if="orderInfo.shop">
        <div class="sctp-pad-tb10 sctp-pad-lr15 sctp-bg-white sctp-flex sctp-flex-sb">
          <div class="sctp-flex sctp-flex-aic">
            <el-image
              fit="cover"
              :src="orderInfo.shop.logo"
              style="height: 35px;width: 35px;"
            ></el-image>
            <div class="sctp-mar-l10">{{ orderInfo.shop.shopName }}</div>
          </div>
        </div>
      </template>
      <template v-if="orderInfo.goods">
        <div class="sctp-bg-white">
          <el-row
            v-for="product in orderInfo.goods"
            :key="product.id"
            class="sctp-pad-tb10"
          >
            <el-col :span="2">
              <div class="sctp-flex sctp-flex-center" style="height: 80px;">
                <el-checkbox :disabled="product.refunded" v-model="product.checked">
                  <template v-if="product.refunded">已申请</template>
                </el-checkbox>
              </div>
            </el-col>
            <el-col :span="22">
              <div class="sctp-flex sctp-flex-aic">
                <el-image
                  fit="cover"
                  :src="product.goodsimg"
                  style="height: 80px;width: 80px;"
                ></el-image>
                <div>
                  <div class="sctp-mar-l10">编号：#{{ product.id }}</div>
                  <div class="sctp-mar-l10">商品名：{{ product.goodsname }}</div>
                  <div class="sctp-mar-l10">数量：x {{ product.amount }}</div>
                  <div class="sctp-mar-l10">金额：¥ {{ product.price }}</div>
                  <div class="sctp-mar-l10">购买日期：{{ dateToString(product.createtime) }}</div>
                </div>
              </div>
            </el-col>
          </el-row>
          <div style="width: 70%;" class="pad-l15">
            <el-input
              type="textarea"
              placeholder="请输入退款原因"
              v-model="reason"
              maxlength="100"
              :rows="4"
              show-word-limit
            ></el-input>
          </div>
          <div>
            <div class="sctp-flex sctp-flex-sb sctp-pad-tb10 sctp-pad-lr15">
              <div>
                <el-button
                  type="primary"
                  size="small"
                  @click="onSubmitBtnClick"
                >
                  申请退款
                </el-button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  name: 'refund-order',
  props: ['id'],
  data() {
    return {
      orderInfo: null,
      reason: null,
    }
  },
  methods: {
    onSubmitBtnClick() {
      const {goods} = this.orderInfo
      if (!goods || goods.length === 0) return
      if (!goods.some(item => item.checked)) {
        this.$message.warning('请选择退款的商品')
        return
      }
      let {reason} = this;
      if (!reason || !reason.trim()) {
        this.$message.warning('请输入退款原因')
        return
      }
      this.$confirm('确认退款?', '提示', {
        type: 'info'
      }).then(() => {
        const ids = goods.filter(item => item.checked).map(item => item.id)
        this.doSubmit(ids)
      })
    },
    doSubmit(opIds) {
      this.$request.post({
        reqcode: '1098',
        reqdesc: '订单申请退款',
        orderId: this.id,
        userId: this.user.userId,
        opIdList: opIds,
        reason: this.reason
      }).then(res => {
        this.$message.success('提交申请成功，等待商家确认')
        this.$router.back();
      })
    },
    goOrderIndexPage() {
      this.goPage('/order/index')
    },
    getOrderForRefund() {
      this.$request.post({
        reqcode: '1105',
        reqdesc: '订单申请退款信息',
        orderId: this.id,
        userId: this.user.userId
      }).then(res => {
        const {retdata} = res
        this.orderInfo = retdata
      })
    }
  },
  computed: {},
  beforeMount() {
    this.getOrderForRefund()
  }
}
</script>

<style scoped>
/*@formatter:off*/

  /*@formatter:on*/
</style>
